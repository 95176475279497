import React, { useEffect, useContext, useState, useMemo, useRef } from "react";  
import websocket from "../helpers/WebSocket";
import Context from "../context/Context";
import {useNetworkingHooks} from "../helpers/NetworkMessaging";
import { useGameState, useTeamSelectionData } from "../helpers/TeamSelectionHooks";
import { golfTouchArea, golfBkgSplash, golfLogo } from "../Sprites/ImagesLoader";
import {CoreGameConnectionsHooks} from "../helpers/CoreGameConnections_Hooks";
import { PageBackground, PlayerBanner } from "../Components/PagePrefabs";
import { TeamSelectionTeamless } from "../Components/TeamSelectionPrefabs";
import { GameplayAreaTeamless } from "../Components/GameplayPrefabs";


export default function Golf(props){
    const context = useContext(Context);

    const useTeamSelectionDataContext = useTeamSelectionData();
    const TeamsRef = useRef();
    TeamsRef.current = useTeamSelectionDataContext.Teams;
    
    const useGameStatecontext = useGameState();
    const GameStateRef = useRef();
    GameStateRef.current = useGameStatecontext.GameState;

    const {SendSpecificMessageToServer, JoinWaitingRoom, Disconnect, CheckReconnectMessages, Reconnect} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages} = CoreGameConnectionsHooks(context.user, useGameStatecontext, useTeamSelectionDataContext);

    const MyTeam = useMemo(() => {return useTeamSelectionDataContext.Teams.myteam}, [useTeamSelectionDataContext]);
    const OtherTeam = useMemo(() => {return useTeamSelectionDataContext.Teams.otherteam}, [useTeamSelectionDataContext]);
    const Spectating = useMemo(() => {return useTeamSelectionDataContext.Teams.myteam === useTeamSelectionDataContext.Teams.neutralteam }, [useTeamSelectionDataContext]);
    

    useEffect(() => {
        //OnMount Things happen here
        document.title = "Golf Controller";

        websocket.AddListener(onPayload);
        window.addEventListener('beforeunload', handleTabClosing);
        
        if(websocket.sessionId === '') Reconnect();
        //SendMessageToServer();
        document.addEventListener("touchmove", preventBehavior, {passive: false});

        console.log(context.user.playerID);
        return () =>{
            //Unmount things happen here
            websocket.RemoveListener(onPayload);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        Disconnect();
    }

   
    function onPayload(id, payload, senderContext){
        console.log(`Payload message recieved ${id} ${payload}`)
        CheckCoreGameConnectionMessages(id, payload, senderContext);
        CheckReconnectMessages(id,payload, senderContext);

        //Game specific calls
        switch(id){
           
            case "TeamSetup":
                SetupController(payload);
                break;
            default:
                break;
        }
    }

    function SetupController(payload)
    {
        useTeamSelectionDataContext.SetTeam_Home({color: payload.homeTeam.color.substring(0, payload.homeTeam.color.length - 2), name: payload.homeTeam.name});
        useTeamSelectionDataContext.SetTeam_Away({color: payload.awayTeam.color.substring(0, payload.awayTeam.color.length - 2), name: payload.awayTeam.name});
    }

    function preventBehavior(e) {
        e.preventDefault(); 
    };

    function TrackingPosition(payload){
        SendSpecificMessageToServer("Golf_Aim", payload);
    }

    function TrackingEnded(){
        SendSpecificMessageToServer("Golf_Fire");
    }

    const TouchInfo = {
        bkg: golfTouchArea,
        reticule: null,
        sendArray: false,
        rate: 15,
        onUpdateTracking: TrackingPosition,
        onTrackingEnd: TrackingEnded

    }

    return (

    <>
        <PageBackground bkgImage={golfBkgSplash} bkgStyle={{filter: 'blur(.125rem)'}} useBlur useLogo={!GameStateRef.current.joinedGame} Logo={golfLogo}/>
        <PlayerBanner myColor={context.user.color}/>
        {!GameStateRef.current.teamsSelected ? 
            <TeamSelectionTeamless userColor={context.user.playerColor} TeamContext={useTeamSelectionDataContext} GameState={GameStateRef.current} smts={(msg) => SendSpecificMessageToServer(msg)} /> 
            : 
            <GameplayAreaTeamless game="Golf" Team={null} TouchBoxInfo={TouchInfo} Spectating={Spectating} Logo={golfLogo} />    
        }

    </>
       
    );
}
