
import { useNavigate}  from "react-router-dom";
import { UseNavitageToPage } from "../Components/PageNavigationElement";

import websocket from "../helpers/WebSocket";
import { useTeamSelectionData } from "./TeamSelectionHooks";


export function CoreGameConnectionsHooks(usercontext, gamestateContext, teamsSelectionContext){
    const navigate = useNavigate();
    const {tryNavigatePage} = UseNavitageToPage();
    const {JoinedTeam, SetTeamsSelected, setIsOnGameLandingPage, setIsOnTeamSelectPage, CanStartGame, IsGameOver, ResetGameState, IsMyTurn, SetCurrentPlayerAvatar} = gamestateContext;

    const CheckCoreGameConnectionMessages = (id, payload, senderContext) =>{
        let isMe = IsMe(senderContext);
        
        console.log(`We are checking for coremessage: `, id, payload, senderContext);

        switch(id){
            case "GoToWaitingRoom":
                navigate("/");
                break;
            case "JoinedGame":
                if(isMe){
                    JoinedTeam();
                }
                break;   

            case "ResponseToJoinRoomRequest":
                if(isMe){
                    JoinedTeam();
                    SetCurrentPlayerAvatar(senderContext);
                    console.log(senderContext);
                    teamsSelectionContext.SelectTeamToPlay("Neutral");
                }
                break;
            case "ResponseToJoinRoomRequest_Teamless":
                if(isMe){
                    JoinedTeam();                    
                    SetCurrentPlayerAvatar(senderContext);
                }
                break;

                    
            case "ReconnectToRoomResponse":
                if(isMe){
                    console.log("Got the reconnect response");
                    
                    JoinedTeam();
                    SetCurrentPlayerAvatar(senderContext);
                    
                    teamsSelectionContext.SelectTeamToPlay(payload.teamSelected);
                    
                    SetTeamsSelected(payload.teamSelectionCompletedStatus === 'True');

                    IsMyTurn(payload.currPlayersTurn === usercontext.playerId);               
                }
                break;
            case "ReconnectToRoomResponse_Teamless":
                if(isMe){
                    console.log("Got the reconnect response_Teamless");

                    JoinedTeam();
                    SetCurrentPlayerAvatar(senderContext);
                    
                    teamsSelectionContext.SelectTeamToPlay("Teamless");
                    SetTeamsSelected(true);

                    IsMyTurn(payload.currPlayersTurn === usercontext.playerId);               
                }
                break;
                
            case "GameOver":
                IsGameOver(true);
                break;

            case "NotGameOver":
                IsGameOver(false);
                break;

            case "TeamSelectionCompleted":
                SetTeamsSelected(true);
                break;
            case "OnTeamSelectScreen":
                //show team selection and start game button
                setIsOnGameLandingPage(false);
                setIsOnTeamSelectPage(true);
                break;
            case "GameLandingPage":
                //Hide team selection and start game button and show SPECIFIC GAME LOBBY
                console.log("WE ARE ON THE GAME LANDING PAGE");
                setIsOnGameLandingPage(true);
                setIsOnTeamSelectPage(false);
                break;
            
            case "SetCurrentTurn":
                console.log("Incoming PlayerID: " , payload.currPlayersTurn, usercontext.playerId );
                IsMyTurn(payload.currPlayersTurn === usercontext.playerId);
            break;

            case "RequestCurrentSelectedTeam":
                if(isMe){
                    console.log(`Checking my currently selected team: ${teamsSelectionContext.Teams.myTeam}`);
                    teamsSelectionContext.RequestCurrentlySelectedTeam();
                }
                break;
            case "SetStartGameButtonState":
                //console.log(`SetStartGameButtonState listener called. here is the check for player: ${payload.playerId} : ${context.user.playerId}`);
                CanStartGame(payload.readyState.toLowerCase() === 'true');

               
                break;

            case "ResetPhoneController":
                if(isMe)
                {
                    ResetGameState();
                    teamsSelectionContext.SelectTeamToPlay("Neutral");
                }
                break;
            case "NavigateToPage":
                tryNavigatePage(payload, usercontext.playerId, websocket.sessionId);
                break;

            case "TeamStateUpdate":
                console.log("Recieved a team state update", payload);
                //receive message letting players know what teams are disabled and what are not 
                //One message that says who has control of each team, and if the teams are now disabled or still active 

                console.log("CurrentState of teambuttondisable: ", teamsSelectionContext.Teams.isawayTeamButtonDisabled, teamsSelectionContext.Teams.ishomeTeamButtonDisabled);
                teamsSelectionContext.setHomeButtonDisableState(payload.homeTeamButtonDisabled);
                teamsSelectionContext.setAwayButtonDisableState(payload.awayTeamButtonDisabled);

                break;
            default:
                break;


        }

        
    }

    const IsMe = (sender) => {
        //console.log("Checking isMe ", sender?.playerId);
        if(sender == null) return false;
        else return sender.playerId === usercontext.playerId;
    }

    return{CheckCoreGameConnectionMessages, IsMe} ;
}