import React, { useContext, useMemo } from "react";
import Context from "../context/Context.js";
import "../Styles/FigmaStyle.css";
import TouchFingerTracker, { TouchPlayerSelection, TouchSlider } from "./TouchFingerTracker.js"
import { TextBox } from "./PagePrefabs.js";
import { TeamSelectionBanner, GameSelectionBanner } from "./TeamSelectionPrefabs.js";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner.js";


export function GameplayArea(props){
    const {game, Team, OpposingTeam, TouchBoxInfo, useBlur, GetSliderValue, Spectating, Logo} = props;
    
    const bkgBlurOverlay = {
        position: useBlur ? "relative": '',
        top: useBlur ? "0": '',
        left: useBlur ? "0": '',
        height: useBlur ? "100%": '',
        width: useBlur ? "100%": '',
        backgroundColor: useBlur ? 'rgba(0,0,0,.25)': '',
        filter: useBlur ? 'blur(.2rem) saturate(50%) brightness(25%)'   : ''   
    }

    

    return(
        <div style={{position: "absolute", width: "100%", height: "85%", top: "15%"}}>
            {Team != null ? <TeamSelectionBanner Team={Team}/> : <></> }        
            <div style={{...bkgBlurOverlay}} >
                <div style={{height: '24px'}}/>
                {!Spectating ? 
                <>
                <TouchFingerTracker game = {game} bkgImg={TouchBoxInfo.bkg} reticuleImg={TouchBoxInfo.reticule} sendArray={TouchBoxInfo.sendArray} intervalRate={TouchBoxInfo.rate}
                    onUpdateTracking={TouchBoxInfo.onUpdateTracking}
                    onTrackingEnd={TouchBoxInfo.onTrackingEnd} 
                />
                <div style={{height: '24px'}}/>
                {game.toLowerCase() == 'shuffleboard' ? <TouchSlider GetSliderValue={(value) => GetSliderValue(value)} /> : <></>}
                {game.toLowerCase() == 'soccer' ? <TouchPlayerSelection Team={Team} /> : <></>}
                {game.toLowerCase() == 'football' ? <TouchSlider GetSliderValue={(value) => GetSliderValue(value)} /> : <></>}

                </>
                : <></> }        
            </div>    
            {Spectating ? <GameplayOverlay_Spectating useBlur Logo={Logo} /> : <GameplayOverlay_AnothersTurn useBlur={useBlur} OpposingTeam={OpposingTeam}/>}
            
            

        </div>
    );
}

export function GameplayAreaTeamless(props){
    const {game, TouchBoxInfo, useBlur, Spectating, Logo} = props;
    
    const bkgBlurOverlay = {
        position: useBlur ? "relative": '',
        top: useBlur ? "0": '',
        left: useBlur ? "0": '',
        height: useBlur ? "100%": '',
        width: useBlur ? "100%": '',
        backgroundColor: useBlur ? 'rgba(0,0,0,.25)': '',
        filter: useBlur ? 'blur(.2rem) saturate(50%) brightness(25%)'   : ''   
    }

    return(
        <div style={{position: "absolute", width: "100%", height: "85%", top: "15%"}}>      
            <GameSelectionBanner Game={{name: game}} />
            <div style={{...bkgBlurOverlay}} >
                <div style={{height: '24px'}}/>
                {!Spectating ? 
                <>
                <TouchFingerTracker game = {game} bkgImg={TouchBoxInfo.bkg} reticuleImg={TouchBoxInfo.reticule} sendArray={TouchBoxInfo.sendArray} intervalRate={TouchBoxInfo.rate}
                    onUpdateTracking={TouchBoxInfo.onUpdateTracking}
                    onTrackingEnd={TouchBoxInfo.onTrackingEnd} 
                />
                <div style={{height: '24px'}}/>
                </>
                : <></> }        
            </div>    
            {Spectating ? <GameplayOverlay_Spectating useBlur Logo={Logo} /> : <GameplayOverlay_AnothersTurn useBlur={useBlur} OpposingTeam={{name: 'donkey', color: 'orange'}}/>}
            
            

        </div>
    );
}

function GameplayOverlay_AnothersTurn(props){
    const {useBlur, OpposingTeam} = props;

    const overlayBackgroundArea = {
        position:'absolute',
        width: "100%",
        height: "100%", 
        top: "0",   
        zIndex: '10'
    }

    const overlayBox = {
        position: 'relative', 
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        width: '90%', 
        height: '400px', 
        margin: '20% auto',
        gap: '12px'
    }

    const overlayTextArea = {
        position: 'relative',
        display: 'flex',
        margin: '0 auto',
        width: '100%',
        height: '48px',
    }

    const overlayLoaderBox = {
        position: 'relative', 
        display: 'flex',
        margin: '0 auto',
        width: '120px', 
        height: '120px',      
    }

    const OtherTeamName = useMemo(() => {
        let s = "'s"
        if(OpposingTeam.name.slice(-1) === "s") s = "'";

        return OpposingTeam.name + s;
    }, [OpposingTeam])



    return(
        <>
        {useBlur ? 
            <div style={{...overlayBackgroundArea}}>
                <div style={{...overlayBox}}>
                    <div style={{...overlayTextArea}}>
                        <TextBox width={'100%'} fontSize={"xx-large"} textAlign={"center"} textWrap={true} text={"PLEASE WAIT"} />
                    </div>
                    <div style={{...overlayLoaderBox}}>
                        <LoadingSpinner size={60} thickness={6} />
                    </div>
                    <div style={{...overlayTextArea}}>
                        <TextBox width={'100%'} fontSize={"xxx-large"} textAlign={"center"} text={OtherTeamName} textStyle={{fontFamily: 'Rajdhani-Bold', color: `${OpposingTeam.color}`}} />
                        
                    </div>
                    <div style={{...overlayTextArea, height: '30px', marginTop:'-12px'}}>
                        <TextBox width={'100%'} fontSize={"xx-large"} textAlign={"center"} text={"TURN"}  />
                    </div>
                    <div style={{...overlayTextArea}}>
                        <TextBox width={'100%'} fontSize={"medium"} textAlign={"center"} text={"Another player is currently taking their turn."} />
                    </div>
                    
                </div>
            </div> : <></>
        }
        </>
    );
}

export function GameplayOverlay_Spectating(props){
    const {useBlur, Logo} = props;

    const gameLogo = useMemo(() => { return  Logo}, [Logo])

    const overlayBackgroundArea = {
        position:'absolute',
        width: "100%",
        height: "100%", 
        top: "0",   
        zIndex: '10',
    }

    const overlayBox = {
        position: 'relative', 
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        width: '90%', 
        height: '400px', 
        margin: '37.5% auto',
        gap: '12px'
    }

    const overlayTextArea = {
        position: 'relative',
        display: 'flex',
        margin: '0 auto',
        width: '100%',
        height: '48px',
    }

    const CenteredLogo = {
        position: 'relative',
        display: 'flex',
        height: 'auto',
        width: '100%',
        objectFit: 'fit',
        filter: "drop-shadow(12px 12px 8px black)",
        margin: '0 auto',
        marginBottom: '92px'
    }


    return(
        <>
        {useBlur ? 
            <div style={{...overlayBackgroundArea}}>
                <div style={{...overlayBox}}>
                    <img id="GameLogo" src={gameLogo} style={{...CenteredLogo}} />
                    <div style={{...overlayTextArea}}>
                        <TextBox width={'100%'} fontSize={"xxx-large"} textAlign={"center"} textWrap={true} text={"SPECTATING"} textStyle={{fontFamily: 'Rajdhani-Bold', color: '#FFD600'}} />
                    </div>
                    <div style={{...overlayTextArea}}>
                        <TextBox width={'100%'} fontSize={"medium"} textAlign={"center"} text={"Game currently in progress"} />
                    </div>
                    
                </div>
            </div> : <></>
        }
        </>
    );
}
