import websocket from "./WebSocket";

export function useNetworkingHooks(UserContext) {

    function SendSpecificMessageToServer(Id, Payload){
    
        var msg = {
            id: Id,
            payload: JSON.stringify(Payload ?? ''),
            userContext: UserContext
        }

        websocket.Send(msg);
    }

    const JoinWaitingRoom = () => {
        SendSpecificMessageToServer("RequestToConnectToRoom");
        
    }

    const Disconnect = () => {
        SendSpecificMessageToServer("DiscontinueConnection");
    }

    const Reconnect = (callback) =>{
        SendSpecificMessageToServer("PhoneClientAttemptReconnect");
    }
    const CheckReconnectMessages = (id, payload, senderContext) =>{
        switch(id){
            case 'phoneClientReconnectServerResponse':
                if(senderContext === ''){
                    console.log("Sending context is empty, sending to play screen");
                    //NAVIGATE TO THE PLAY SCREEN FROM HERE 
                }else{
                    JoinWaitingRoom(senderContext);

                }
                break;
        }
    }


    return { SendSpecificMessageToServer, JoinWaitingRoom, Disconnect, CheckReconnectMessages, Reconnect};
}


